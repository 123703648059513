import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';

type ThankYouLearnersProps = {
    learnerAssignmentLink?: string;
};

const ThankYouLearners: FC<ThankYouLearnersProps> = ({ learnerAssignmentLink }) => {
    const { formatMessage } = useFormat({ name: 'thank-you' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { formatMessage: formatCustomerSupportMessage } = useFormat({ name: 'customer-support' });

    if (!learnerAssignmentLink) {
        return null;
    }

    return (
        <div className="grid border-b border-neutral-400 pb-24 pt-16 md:border-b-0 lg:pb-0 lg:pt-26">
            <Typography className="mb-16 w-fit font-heading text-18 leading-[20px] text-primary-black md:mb-24 md:leading-normal">
                {formatMessage({ id: 'thank.you.add.learners.heading', defaultMessage: 'Add learners to complete the proccess' })}
            </Typography>
            <Link link={learnerAssignmentLink} target="_blank">
                <Button className="w-full !bg-learner-accent py-14 md:w-fit md:px-56">
                    {formatCommonMessage({ id: 'add.learners', defaultMessage: 'Add learners' })}
                </Button>
            </Link>
            <Link link={'/help-and-support/contact-us/'} target="_blank">
                <Button className="mt-10 w-full md:w-fit md:px-56">
                    {formatCustomerSupportMessage({ id: 'contact.us', defaultMessage: 'Contact us' })}
                </Button>
            </Link>
        </div>
    );
};

export default ThankYouLearners;
