'use client';
import React, { useMemo } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import LearnerOrderLineItem from 'components/learner/organisms/content/line-item';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLearnerDataContext } from 'providers/learner/data';

interface LearnerContentProps {
    pageName: string;
}

const LearnerContent: React.FC<LearnerContentProps> = ({ pageName }) => {
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
    const { learnerSettings, learnerOrderInfo, handleTemplateGeneration, openAssigningModal } = useLearnerDataContext();

    const downloadTemplateCSVText = useMemo(() => {
        return formatCommonMessage({ id: 'download.template.csv', defaultMessage: 'Download template CSV' });
    }, [formatCommonMessage]);

    const { learnerOrderLineItemsWithProvisionEnabled, learnerOrderLineItemsWithoutProvisionEnabled } = useMemo(() => {
        const learnerOrderLineItemsWithoutBundleComponents = learnerOrderInfo?.order.lineItems.filter(
            lineItem => !lineItem.bundleLineItemId
        );
        const learnerOrderLineItemsWithProvisionEnabled = learnerOrderLineItemsWithoutBundleComponents?.filter(
            lineItem => lineItem.isProvisionEnabled
        );
        const learnerOrderLineItemsWithoutProvisionEnabled = learnerOrderLineItemsWithoutBundleComponents?.filter(
            lineItem => !lineItem.isProvisionEnabled
        );

        return {
            learnerOrderLineItemsWithProvisionEnabled,
            learnerOrderLineItemsWithoutProvisionEnabled
        };
    }, [learnerOrderInfo]);

    if (!learnerOrderInfo) {
        return <div>Loading...</div>;
    }

    return (
        <div className="min-h-[calc(100dvh-230px)] bg-white sm:bg-checkout-bg sm:p-30">
            <div className="block border-b border-checkout-step-border p-16 sm:mb-30 sm:px-0 sm:pb-30 sm:pt-0">
                <h6 className="leading-22 text-lg capitalize sm:text-22">{pageName}</h6>
            </div>
            <div className="grid grid-cols-12 bg-white p-16 sm:p-30">
                <h1 className="col-span-8">{learnerSettings.pageHeading.replaceAll('{orderNumber}', learnerOrderInfo?.order?.number)}</h1>
                <div className="col-span-4 text-end">
                    <Link
                        onClick={handleTemplateGeneration}
                        variant="primary"
                        className="font-bold !text-learner-accent-1"
                        title={downloadTemplateCSVText}
                        link={'#'}
                    >
                        {downloadTemplateCSVText}
                    </Link>
                </div>
                <div className="col-span-12 mt-8 text-sm sm:col-span-8">
                    <Markdown markdown={learnerSettings.pageSubheadingMessage} />
                </div>
                <div className=" col-span-12 grid grid-flow-row gap-8">
                    <div className="mt-14 grid grid-cols-2 gap-4 text-center sm:grid-cols-[4fr_1fr_1fr_2fr]">
                        <p className="mb-12 hidden bg-gray-100 p-8 text-left sm:inline-block">{formatCommonMessage({ id: 'line.item' })}</p>
                        <p className="mb-12 hidden bg-gray-100 p-8 sm:inline-block">{formatCommonMessage({ id: 'unassigned.seats' })}</p>
                        <p className="mb-12 hidden bg-gray-100 p-8 sm:inline-block">{formatCommonMessage({ id: 'total.seats' })}</p>
                        <p className="mb-12 hidden bg-gray-100 p-8 sm:inline-block">{formatCommonMessage({ id: 'action' })}</p>
                        {learnerOrderLineItemsWithProvisionEnabled?.map(lineItem => (
                            <LearnerOrderLineItem key={lineItem.lineItemId} lineItem={lineItem} openAssigningModal={openAssigningModal} />
                        ))}
                        {learnerOrderLineItemsWithoutProvisionEnabled?.map(lineItem => (
                            <LearnerOrderLineItem key={lineItem.lineItemId} lineItem={lineItem} openAssigningModal={openAssigningModal} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="mb-30 mr-16 grid-cols-12 text-right sm:mb-0 sm:mr-0 sm:mt-30">
                <Link link={'/account'} title="My Account">
                    <Button variant="primary">{formatCommonMessage({ id: 'my.account', defaultMessage: 'My Account' })}</Button>
                </Link>
            </div>
        </div>
    );
};

export default LearnerContent;
