'use client';

import React, { useCallback, useState } from 'react';
import TrashIcon from 'components/icons/trash';
import Quantity from 'components/sales-link/atoms/quantity';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import { BundleMode } from '@wilm/shared-types/product/Bundle';
import { ProductTypeKey } from '@wilm/shared-types/product/Product';
import LineItemPrice from 'components/sales-link/organisms/content/summary/line-item/price';
import useSalesLinkProduct from 'frontastic/hooks/useSalesLinkProduct';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import type { Money } from '@commercetools/frontend-domain-types/product';
import { useFormat } from 'helpers/hooks/useFormat';
import { useSalesLinkDataContext } from 'providers/sales-link/data';

interface Props {
    lineItem: LineItem;
    bundleMode: BundleMode;
    bundleComponents: LineItem[];
    counterClassName: string;
    quantityChange: (lineItemId: string, count: number, price: Money, bundleComponents: LineItem[]) => void;
}

const SummaryLineItem: React.FC<Props> = ({ lineItem, bundleMode, bundleComponents, counterClassName, quantityChange }) => {
    const {
        customerInfo,
        inventoryCheck,
        removeFromCart,
        changeLineItemCustomField,
        isCartLoading,
        exemptFromVATLineItemIds,
        setExemptFromVATLineItemIds
    } = useSalesLinkCartContext();
    const { salesLinkSettings } = useSalesLinkDataContext();
    const { getProductAttributesToShow, isRenewable, isDelegate } = useSalesLinkProduct();
    const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
    const { formatMessage: formatSLMessage } = useFormat({ name: 'sales-link' });

    const qtyMin = FieldValidationRules.LINE_ITEM_QUANTITY.MIN;
    const qtyMax = FieldValidationRules.LINE_ITEM_QUANTITY.MAX;

    const bundleWithSelectComponentMode =
        (bundleMode === BundleMode.SELECT_COMPONENTS || bundleMode === BundleMode.SELECT_GROUP) &&
        lineItem.productTypeKey === ProductTypeKey.BUNDLE;

    const [quantityValue, setQuantityValue] = useState(lineItem.count!);

    const onQtyChange = useCallback(
        (count: number) => {
            setQuantityValue(count);
            quantityChange(lineItem.lineItemId, count, lineItem.price!, bundleComponents);
        },
        [lineItem.lineItemId, lineItem.price, bundleComponents, quantityChange]
    );

    const [isOpen, setIsOpen] = useState(false);

    const showQuantityEdit = customerInfo?.isB2B;

    const displayAttributes = getProductAttributesToShow(lineItem.productTypeKey!, lineItem.variant!, '', false);

    const isRenewableProduct = isRenewable(lineItem.productTypeKey!, lineItem.variant!);

    const isDelegateProduct = isDelegate(lineItem.productTypeKey!, customerInfo?.isB2B ?? false);

    const isLPBundle = lineItem.productTypeKey === ProductTypeKey.BUNDLE && !!lineItem.variant?.attributes?.administrateLearningPathId;
    const approvalRequiredForSKUs = new Set(lineItem.approvalRequiredForSKUs ?? []);

    const lineItemQtyErrorMessageId = inventoryCheck?.outOfStockItems?.[lineItem.variant!.sku]?.message
        ? 'error.lineitem.' + inventoryCheck.outOfStockItems[lineItem.variant!.sku].message
        : '';

    const itemQtyError: string = lineItemQtyErrorMessageId
        ? formatErrorMessage({
              id: lineItemQtyErrorMessageId,
              defaultMessage: lineItemQtyErrorMessageId,
              values: {
                  availableQuantity: inventoryCheck.outOfStockItems[lineItem.variant!.sku].availableQuantity
              }
          })
        : '';

    const handleSkipChange = useCallback(
        (checked: boolean, sku: string) => {
            if (checked) {
                approvalRequiredForSKUs.add(sku);
            } else {
                approvalRequiredForSKUs.delete(sku);
            }
            changeLineItemCustomField(lineItem.lineItemId, {
                name: 'approvalRequiredForSKUs',
                value: Array.from(approvalRequiredForSKUs)
            });
        },
        [lineItem.lineItemId, changeLineItemCustomField, approvalRequiredForSKUs]
    );

    const handleVatExemptionCheckbox = useCallback(
        (lineItemId: string, checked: boolean) => {
            if (checked) {
                exemptFromVATLineItemIds.add(lineItemId);
            } else {
                exemptFromVATLineItemIds.delete(lineItemId);
            }
            setExemptFromVATLineItemIds(new Set(exemptFromVATLineItemIds));
        },
        [exemptFromVATLineItemIds, setExemptFromVATLineItemIds]
    );

    const renderVatExemptionCheckbox = useCallback(
        (lineItemId: string, isChecked: boolean) => (
            <div className="mt-8">
                <Checkbox
                    smallLabel={true}
                    label={formatSLMessage({ id: 'force.exempt.from.vat', defaultMessage: 'Force exempt from VAT (0%)' })}
                    name={lineItemId + '_exemptVAT'}
                    id={lineItemId + '_exemptVAT'}
                    onChange={({ checked }) => {
                        handleVatExemptionCheckbox(lineItemId, checked);
                    }}
                    checked={isChecked}
                />
            </div>
        ),
        [formatSLMessage, handleVatExemptionCheckbox]
    );

    return (
        <div key={lineItem.variant!.sku} className="border-checkout-border border-b py-14">
            <div className="flex justify-between break-words">
                <div className="mb-8 w-[calc(100%-30px)]">
                    <button className="flex text-left" onClick={() => setIsOpen(!isOpen)}>
                        <p className="mr-10 font-bold">{lineItem.name}</p>
                        <ChevronDownIcon width={18} strokeWidth={1} className={`mt-4 min-w-18 ${isOpen && 'rotate-180'}`} />
                    </button>
                    <div className={`max-h-0 overflow-hidden transition-[max-height] ${isOpen && 'mt-8 max-h-200'}`}>
                        {displayAttributes.map(
                            attribute =>
                                attribute.key !== 'bundleComponents' && (
                                    <p key={`bundle_component_${attribute.key}`} className="mb-4 text-md">
                                        <span className="pr-8 font-bold">{attribute.label}:</span>
                                        <span>{attribute.value}</span>
                                    </p>
                                )
                        )}
                    </div>
                </div>
                {/* // remove button */}
                <div>
                    <button className="text-sm underline" onClick={() => removeFromCart(lineItem.lineItemId)}>
                        <TrashIcon className="size-20 text-secondary-black" />
                    </button>
                </div>
            </div>
            {bundleWithSelectComponentMode &&
                bundleComponents.map(component => (
                    <div key={component.variant!.sku} className="py-12 pl-16">
                        <p className="mb-4 font-bold">{component.name}</p>
                        <LineItemPrice lineItem={component} />
                        {isLPBundle && (
                            <div className="mt-8">
                                <Checkbox
                                    smallLabel={true}
                                    label={formatSLMessage({ id: 'skip.provisioning', defaultMessage: 'Skip provisioning' })}
                                    name={component.lineItemId + '_approvalRequired'}
                                    id={component.lineItemId + '_approvalRequired'}
                                    onChange={({ checked }) => handleSkipChange(checked, component.variant!.sku)}
                                    checked={!!component.variant?.sku && approvalRequiredForSKUs.has(component.variant?.sku)}
                                    disabled={isCartLoading}
                                />
                            </div>
                        )}
                        {salesLinkSettings.vatExemptionEnabled &&
                            renderVatExemptionCheckbox(component.lineItemId, exemptFromVATLineItemIds.has(component.lineItemId))}
                    </div>
                ))}
            <Quantity
                count={quantityValue}
                counterClassName={counterClassName}
                updateCartItem={onQtyChange}
                showQuantityEdit={showQuantityEdit}
                min={qtyMin}
                max={qtyMax}
                error={itemQtyError}
            />
            {!bundleWithSelectComponentMode && (
                <div className="mt-14">
                    <LineItemPrice lineItem={lineItem} />
                </div>
            )}

            {/* if lineitem.attributes  isRenewable key exist show checkbox */}
            {/* // enable auto renewal */}
            {isRenewableProduct && (
                <Checkbox
                    smallLabel={true}
                    label={formatSLMessage({ id: 'enable.auto.renewal', defaultMessage: 'Enable auto renewal' })}
                    name={lineItem.lineItemId + '_isRenewable'}
                    id={lineItem.lineItemId + '_isRenewable'}
                    containerClassName="mt-14"
                    onChange={({ checked }) => {
                        changeLineItemCustomField(lineItem.lineItemId, { name: 'isRenewable', value: checked }, bundleComponents);
                    }}
                    checked={false}
                    disabled={isCartLoading}
                />
            )}
            {isDelegateProduct && (
                <Checkbox
                    smallLabel={true}
                    label={formatSLMessage({ id: 'the.customer.is.delegate', defaultMessage: 'The customer is a delegate' })}
                    name={lineItem.lineItemId + '_isDelegate'}
                    id={lineItem.lineItemId + '_isDelegate'}
                    containerClassName="mt-14"
                    onChange={({ checked }) => {
                        changeLineItemCustomField(lineItem.lineItemId, { name: 'isDelegate', value: checked }, bundleComponents);
                    }}
                    checked={false}
                    disabled={isCartLoading}
                />
            )}

            {salesLinkSettings.vatExemptionEnabled &&
                !isLPBundle &&
                renderVatExemptionCheckbox(lineItem.lineItemId, exemptFromVATLineItemIds.has(lineItem.lineItemId))}
        </div>
    );
};

export default SummaryLineItem;
