import type { ChangeEvent, FC } from 'react';
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { validate } from '@wilm/shared-types/validation-rules';
import { fields as accountFields } from '@wilm/shared-types/validation-rules/account/addresses';
import type { Field, FieldErrors, Fields } from '@wilm/shared-types/validation-rules/types';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useFormat } from 'helpers/hooks/useFormat';
import { useSalesLinkCartContext } from 'providers/sales-link/cart';
import useSalesLinkProduct from 'frontastic/hooks/useSalesLinkProduct';
import DelegateFields from './delegate-fields';

export interface Props {
    customer: { firstName: string; lastName: string; email: string };
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (productsDelegates: ProductsDelegates) => Promise<void>;
}

type ProductsDelegates = Record<string, { name: string; delegates: Record<string, Fields>; variantSku?: string }>;
type DelegatesErrors = {
    hasErrors: boolean;
    errors: Record<string, Record<string, FieldErrors>>;
};

const DelegatesModal: FC<Props> = ({ customer, isOpen, onClose, onSubmit }) => {
    const { formatMessage } = useFormat({ name: 'sales-link' });
    const [fieldsErrors, setFieldsErrors] = useState<DelegatesErrors>({ hasErrors: false, errors: {} });
    const { isDelegate: checkIsDelegate } = useSalesLinkProduct();
    const { data: cart } = useSalesLinkCartContext();

    const initialFields = useMemo(() => {
        return {
            firstName: accountFields.firstName,
            lastName: accountFields.lastName,
            email: accountFields.email
        } as Fields;
    }, []);

    const initialProductsDelegates: ProductsDelegates = useMemo(() => {
        return (
            cart.lineItems?.reduce((acc, lineItem) => {
                const customerIsDelegate = lineItem.isDelegate;
                const parentBundleProductLineItemId = lineItem.parentBundleProductLineItemId;
                const isBundleComponent = !!parentBundleProductLineItemId;
                const isParentBundleLearningPath = !!cart.lineItems?.find(item => item.lineItemId === parentBundleProductLineItemId)
                    ?.variant?.attributes?.administrateLearningPathId;
                const isLPBundleComponent = isBundleComponent && isParentBundleLearningPath;
                const isDelegate = checkIsDelegate(lineItem.productTypeKey!, true);

                // exclude bundle components from the list of delegates if the bundle is a learning path
                if (isDelegate && !isLPBundleComponent) {
                    const delegates = {} as Record<string, Fields>;
                    for (let i = 0; i < lineItem.count!; i++) {
                        if (customerIsDelegate && customer && i === 0) {
                            delegates[i] = {
                                firstName: {
                                    ...initialFields.firstName,
                                    value: customer.firstName,
                                    disabled: true
                                },
                                lastName: {
                                    ...initialFields.lastName,
                                    value: customer.lastName,
                                    disabled: true
                                },
                                email: {
                                    ...initialFields.email,
                                    value: customer.email,
                                    disabled: true
                                }
                            } as Fields;
                        } else {
                            delegates[i] = initialFields;
                        }
                    }
                    acc[lineItem.lineItemId] = {
                        name: lineItem.name!,
                        delegates,
                        variantSku: lineItem.variant?.sku
                    };
                }
                return acc;
            }, {} as ProductsDelegates) ?? ({} as ProductsDelegates)
        );
    }, [cart, checkIsDelegate, initialFields, customer]);

    const [productsDelegates, setProductsDelegates] = useState(initialProductsDelegates);

    useEffect(() => {
        if (isOpen) {
            setProductsDelegates(initialProductsDelegates);
        }
    }, [isOpen]);

    const handleClose = () => {
        // setError('');
        // setData(initailData);
        onClose();
    };

    const validateDelegates = (productsDelegates: ProductsDelegates) => {
        const validateDelegatesResult: DelegatesErrors = {
            hasErrors: false,
            errors: {}
        };
        function validateDelegate(delegate: Fields): FieldErrors {
            const fieldErrors: FieldErrors = {};

            Object.entries(delegate).forEach(([fieldName, field]) => {
                const error = validate(field, delegate);
                if (Object.keys(error).length > 0) {
                    fieldErrors[fieldName] = error;
                }
            });

            return fieldErrors;
        }

        function validateProduct(product: { name: string; delegates: Record<string, Fields> }): Record<string, FieldErrors> {
            const productErrors: Record<string, FieldErrors> = {};

            Object.entries(product.delegates).forEach(([index, delegate]) => {
                const delegateErrors = validateDelegate(delegate);
                if (Object.keys(delegateErrors).length > 0) {
                    productErrors[index] = delegateErrors;
                }
            });

            return productErrors;
        }

        Object.entries(productsDelegates).forEach(([lineItemId, product]) => {
            const productErrors = validateProduct(product);

            if (Object.keys(productErrors).length > 0) {
                validateDelegatesResult.errors[lineItemId] = productErrors;
                validateDelegatesResult.hasErrors = true;
            }
        });

        return validateDelegatesResult;
    };

    const handleSubmit = useCallback(
        (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            const productsDelegatesWithTrimmedValues: ProductsDelegates = {};

            for (const lineItemId in productsDelegates) {
                const product = productsDelegates[lineItemId];
                const delegates: Record<string, Fields> = {};

                for (const index in product.delegates) {
                    const delegate = product.delegates[index];
                    const trimmedDelegate: Fields = {};

                    for (const fieldName in delegate) {
                        const field = delegate[fieldName];
                        trimmedDelegate[fieldName] = { 
                            ...field, 
                            value: typeof field.value === 'string' ? field.value.trim() : field.value
                        } as Field;
                    }

                    delegates[index] = trimmedDelegate;
                }

                productsDelegatesWithTrimmedValues[lineItemId] = { ...product, delegates };
            }

            const validateDelegatesResult = validateDelegates(productsDelegatesWithTrimmedValues);

            if (validateDelegatesResult.hasErrors) {
                setFieldsErrors(validateDelegatesResult);
                return;
            }

            const productsDelegatesCopy = structuredClone(productsDelegatesWithTrimmedValues);

            // copy bundle delegates to its components
            for (const lineItem of cart.lineItems ?? []) {
                if (lineItem.parentBundleProductLineItemId) {
                    const parentBundleProductLineItemId = lineItem.parentBundleProductLineItemId;
                    const parentBundleProduct = productsDelegates[parentBundleProductLineItemId];
                    const parentBundleProductDelegates = parentBundleProduct.delegates;

                    productsDelegatesCopy[lineItem.lineItemId] = {
                        name: lineItem.name!,
                        delegates: parentBundleProductDelegates
                    };
                }
            }

            void onSubmit(productsDelegatesCopy);
        },
        [onSubmit, productsDelegates]
    );

    const handleFieldChange = (field: Field, value: string, lineItemId: string, index: string) => {
        setProductsDelegates(prevProductsDelegates => {
            const newProductsDelegates: ProductsDelegates = {
                ...prevProductsDelegates,
                [lineItemId]: {
                    ...prevProductsDelegates[lineItemId],
                    delegates: {
                        ...prevProductsDelegates[lineItemId].delegates,
                        [index]: {
                            ...prevProductsDelegates[lineItemId].delegates[index],
                            [field.name]: {
                                ...prevProductsDelegates[lineItemId].delegates[index][field.name],
                                value
                            } as Field
                        }
                    }
                }
            };

            return newProductsDelegates;
        });
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [parsedData, setParsedData] = useState<any[]>([]);
    const [csvPrefillErrors, setCsvPrefillErrors] = useState<string>('');

    useEffect(() => {
        if (!csvPrefillErrors) {
            return;
        }

        setProductsDelegates(initialProductsDelegates);

        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
    }, [csvPrefillErrors, fileInputRef.current]);

    useEffect(() => {
        if (isParsedDataValid(parsedData)) {
            const productsDelegatesCopy = structuredClone(productsDelegates);

            for (const [lineItemId, product] of Object.entries(productsDelegates)) {
                processProductDelegates(lineItemId, product, parsedData, productsDelegatesCopy);
            }

            setProductsDelegates(productsDelegatesCopy);
        }
    }, [parsedData]);

    function isParsedDataValid(data: any[]): boolean {
        return Array.isArray(data) && data.length > 0;
    }

    function processProductDelegates(
        lineItemId: string,
        product: { delegates: Record<string, Fields>; variantSku?: string },
        parsedData: any[],
        productsDelegatesCopy: ProductsDelegates
    ): void {
        const allDataForCurrentProduct = filterDataForProduct(parsedData, product.variantSku);
        const firstDelegateHasPrefilledFields = hasPrefilledFields(product.delegates[0]);

        allDataForCurrentProduct.forEach((person, index) => {
            if (shouldSkipFirstDelegate(firstDelegateHasPrefilledFields, index)) {
                return;
            }

            updateDelegate(lineItemId, index, person, productsDelegatesCopy, product.delegates);
        });
    }

    function filterDataForProduct(parsedData: any[], sku?: string): any[] {
        return parsedData.filter(data => data.ProductSKU === sku);
    }

    function hasPrefilledFields(delegate: Fields): boolean {
        return Boolean(delegate?.email?.value && delegate?.firstName?.value && delegate?.lastName?.value);
    }

    function shouldSkipFirstDelegate(firstDelegateHasPrefilledFields: boolean, index: number): boolean {
        return firstDelegateHasPrefilledFields && index === 0;
    }

    function updateDelegate(
        lineItemId: string,
        index: number,
        person: any,
        productsDelegatesCopy: ProductsDelegates,
        delegates: Record<string, Fields>
    ): void {
        const currentDelegate = delegates[index];

        if (!currentDelegate) {
            setCsvPrefillErrors('Please upload a valid .csv file or download the template');
            return;
        }

        const updatedDelegate: Fields = {
            ...currentDelegate,
            firstName: {
                ...currentDelegate.firstName,
                value: person.firstName
            },
            lastName: {
                ...currentDelegate.lastName,
                value: person.lastName
            },
            email: {
                ...currentDelegate.email,
                value: person.email
            }
        };

        productsDelegatesCopy[lineItemId].delegates[index] = updatedDelegate;
    }

    // Function to parse CSV content
    const parseCSV = (csvContent: any) => {
        const lines = csvContent.split('\n'); // Split by new lines
        const headers = lines[0].split(','); // First line contains headers
        const data = [];

        // Loop through each row, starting from the second line (index 1)
        for (let i = 1; i < lines.length; i++) {
            const row = lines[i].split(',');
            if (row.length === headers.length) {
                // Ensure valid row length
                const obj: any = {};
                headers.forEach((header: any, index: number) => {
                    obj[header.trim()] = row[index].trim();
                });
                data.push(obj);
            }
        }

        return data;
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];
        if (file) {
            if (file.type !== 'text/csv') {
                if (fileInputRef.current?.value) {
                    fileInputRef.current.value = '';
                }
                setCsvPrefillErrors('Please make sure that the file is in .csv format');
                return;
            }

            const reader = new FileReader();

            reader.onload = e => {
                const content = e?.target?.result;
                const data: any = parseCSV(content);

                const expectedColumns = ['firstName', 'lastName', 'email', 'ProductSKU'];

                for (const person of data) {
                    const objKeys = Object.keys(person);

                    if (
                        objKeys.length !== expectedColumns.length ||
                        !expectedColumns.every(key => {
                            return objKeys.includes(key);
                        })
                    ) {
                        setCsvPrefillErrors('Please upload a valid .csv file or download the template');
                        return;
                    }
                }

                setParsedData(data);
            };

            reader.readAsText(file);
            setCsvPrefillErrors('');
            setProductsDelegates(initialProductsDelegates);
        }
    };

    const handleClearFile = useCallback(() => {
        setParsedData([]);
        setProductsDelegates(initialProductsDelegates);
        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
    }, [initialProductsDelegates]);

    const handleTemplateGeneration = useCallback(() => {
        let csvContent = 'ProductSKU,firstName,lastName,email\n';

        for (const [, product] of Object.entries(productsDelegates)) {
            for (const delegate of Object.values(product.delegates)) {
                if (delegate?.firstName?.value && delegate?.lastName?.value && delegate?.email?.value) {
                    csvContent += `${product.variantSku},${delegate.firstName.value},${delegate.lastName.value},${delegate.email.value}\n`;
                } else {
                    csvContent += `${product.variantSku}\n`;
                }
            }
        }

        const blob = new Blob([csvContent], { type: 'text/csv' });
        const link = document.createElement('a');

        link.download = 'Learner_details_template.csv';
        link.href = window.URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
    }, [productsDelegates]);

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '1200px', maxHeight: 'calc(100% - 80px)', overflow: 'auto' } }}
            preventScroll={true}
        >
            <form autoComplete="off" noValidate onSubmit={e => handleSubmit(e)}>
                <div className="flex flex-col gap-25 p-15 text-base leading-6 md:gap-20 md:p-40">
                    {productsDelegates &&
                        Object.entries(productsDelegates).map(([lineItemId, product]) => {
                            return (
                                <div key={lineItemId} className="mb-16">
                                    <p className="bg-gray-200 p-10 font-bold">{product.name}</p>

                                    {Object.entries(product.delegates).map(([index, field]) => {
                                        return (
                                            <DelegateFields
                                                key={index}
                                                fields={field}
                                                handleFieldChange={(field, value) => handleFieldChange(field, value, lineItemId, index)}
                                                fieldErrors={fieldsErrors.errors[lineItemId]?.[index]}
                                            />
                                        );
                                    })}
                                </div>
                            );
                        })}
                    <div className="flex items-center justify-between gap-12">
                        <div className="flex items-center space-x-4">
                            <label
                                htmlFor="file-input"
                                className="inline-flex cursor-pointer items-center rounded-md bg-green-600 p-10 text-white shadow-md hover:bg-green-500"
                            >
                                Upload Learners CSV
                            </label>
                            <input
                                ref={fileInputRef}
                                type="file"
                                id="file-input"
                                accept=".csv"
                                onChange={handleFileUpload}
                                className="hidden"
                            />
                            {/* Display the selected file name */}
                            {fileInputRef?.current?.files?.[0] ? (
                                <span className="text-sm text-gray-700">{fileInputRef.current.files[0].name}</span>
                            ) : (
                                <span className="text-sm text-gray-500">No file chosen</span>
                            )}

                            {!csvPrefillErrors && fileInputRef?.current?.files?.[0] && (
                                <button onClick={handleClearFile} type="button" className={'!ml-20 underline'}>
                                    Clear all
                                </button>
                            )}

                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleTemplateGeneration}
                                className="!ml-20 rounded-md md:rounded-lg"
                            >
                                {formatMessage({ id: 'download.template', defaultMessage: 'Download template' })}
                            </Button>
                        </div>

                        <div className="flex items-center space-x-4">
                            <Button variant="secondary" onClick={handleClose} className="rounded-md md:rounded-lg">
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit" className="rounded-md md:rounded-lg">
                                Create Order
                            </Button>
                        </div>
                    </div>
                    {csvPrefillErrors && (
                        <div className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700" role="alert">
                            <strong className="font-bold">{csvPrefillErrors}</strong>
                        </div>
                    )}
                </div>
            </form>
        </Modal>
    );
};

export default DelegatesModal;
