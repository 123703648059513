import type { FC } from 'react';
import React, { useContext, createContext, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import type { Product } from '@wilm/shared-types/product/Product';
import type { Variant } from '@wilm/shared-types/product/Variant';
import { fields } from '@wilm/shared-types/validation-rules/account/addresses';
import type { LineItemAddToCartAttributes, CartCustomerAddress, ProductsDelegates } from '@wilm/shared-types/sales-link/SalesLinkCart';
import { PaymentMethod, AddressType, type SalesLinkInfoType, type CheckoutResponse } from '@wilm/shared-types/sales-link/SalesLink';
import type { CustomerAddressFields } from '@wilm/shared-types/validation-rules/account/addresses';
import { hasErrorsInAddress } from '@wilm/shared-types/validation-rules/sales-link/cart';
import EmailConfirmationModal from 'components/sales-link/organisms/email-confirmation-modal';
import MessageModal, { type MessageModalProps } from 'components/sales-link/organisms/message-modal';
import PaymentLinkModal from 'components/sales-link/organisms/payment-link-modal';
import { useSalesLinkDataContext } from 'providers/sales-link/data';
import { sdk } from 'sdk';
import type { Cart } from '@wilm/shared-types/cart';
import type {
    CheckIfCreateInHouseIsAvailableSuccessResponse,
    CreateInHousePayload,
    CreateInHouseSuccessResponse,
    SalesLinkResponse,
    UpdateCartSuccessResponse
} from '@wilm/shared-types/sales-link/Api';
import type { Money } from '@commercetools/frontend-domain-types/product';
import type { LineItem } from '@wilm/shared-types/cart/LineItem';
import type { AddressExtended } from '@wilm/shared-backend/commerce-commercetools/interfaces/AddressExtended';
import { validate } from '@wilm/shared-types/validation-rules';
import type { Field } from '@wilm/shared-types/validation-rules/types';
import type { CartInventoryCheckResult } from '@wilm/shared-types/cart/Cart';
import DelegatesModal from 'components/sales-link/organisms/delegates-modal';
import useSalesLinkProduct from 'frontastic/hooks/useSalesLinkProduct';
import scrollToError from 'helpers/utils/scrollToError';

interface SalesLinkCartProviderProps {
    salesLinkInfo: SalesLinkInfoType;
    children: React.ReactNode;
}

interface SalesLinkCartContextShape {
    data: Cart;
    inventoryCheck: CartInventoryCheckResult;
    isShippingRequired: boolean;
    billingFields: CustomerAddressFields;
    shippingFields: CustomerAddressFields;
    handleAddressFieldChange: (field: Field, value: string | boolean, addressType: AddressType) => void;
    sameAsBillingAddress: boolean;
    handleSameAsBillingAddressChange: (sameAsBilling: boolean) => Promise<boolean>;
    resetAddressFields: (addressType: AddressType) => void;
    cartHasValidBillingAddress: boolean;
    cartHasValidShippingAddress: boolean;
    transaction: {
        subtotal: Money;
        discount: Money;
        tax: Money;
        shipping: Money;
        total: Money;
        totalAmount: Money;
    };
    customerInfo:
        | {
              id: string;
              email: string;
              isB2B: boolean;
              billingAddress: AddressExtended | undefined;
              shippingAddress: AddressExtended | undefined;
              vatId: string;
              isCharity: boolean;
          }
        | undefined;
    taxCalculated: boolean;
    isCartLoading: boolean;
    isAddedToCart: (sku: string) => boolean;
    checkIfCreateInHouseEventIsAvailable: () => Promise<boolean>;
    createInHouseEvent: (productInfo: CreateInHousePayload['product']) => Promise<string | false>;
    addToCart: (product: Product, selectedVariant: Variant, attributes?: LineItemAddToCartAttributes) => Promise<void>;
    removeFromCart: (lineItemId: string) => Promise<void>;
    changeLineItemQuantity: (lineItemId: string, newQuantity: number, externalPrice: Money, bundleComponents?: LineItem[]) => Promise<void>;
    changeLineItemPrice: (lineItemId: string, externalPrice: Money) => Promise<void>;
    changeLineItemCustomField: (lineItemId: string, customField: { name: string; value: any }, bundleComponents?: LineItem[]) => void;
    purchaseCart: (paymentMethod: string) => Promise<void>;
    changeCartCustomerAddressValue: (addressFields: CustomerAddressFields, addressType: AddressType) => Promise<boolean>;
    calculateTaxes: () => Promise<void>;
    selectedCurrency: string;
    handleCurrecySelection: (currency: string, la1Id: string) => Promise<void>;
    termsAccepted: boolean;
    setTermsAccepted: (termsAccepted: boolean) => void;
    isPONumberRequired: boolean;
    setIsPONumberRequired: (isPONumberRequired: boolean) => void;
    exemptFromVATLineItemIds: Set<string>;
    setExemptFromVATLineItemIds: (exemptFromVATLineItemIds: Set<string>) => void;
}

const SalesLinkCartContext = createContext<SalesLinkCartContextShape>({} as SalesLinkCartContextShape);

const SalesLinkCartProvider: FC<SalesLinkCartProviderProps> = ({ salesLinkInfo, children }) => {
    const router = useRouter();

    const hash = salesLinkInfo.hash;
    if (!hash || typeof hash !== 'string') {
        throw new Error('Token is wrong or missing');
    }

    const { query, setQuery, searchProductsBySku, salesLinkSettings, productTypes } = useSalesLinkDataContext();
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [messageModalData, setMessageModalData] = useState<MessageModalProps['data']>({ message: '', type: 'error' });
    const [paymentLink, setPaymentLink] = useState('');
    const [learnerAssignmentPageLink, setLearnerAssignmentPageLink] = useState('');
    const [isPaymentLinkModalOpen, setIsPaymentLinkModalOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [taxCalculated, setTaxCalculated] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [isPONumberRequired, setIsPONumberRequired] = useState(false);
    const [inventoryCheck, setInventoryCheck] = useState<CartInventoryCheckResult>({
        hasOutOfStockItems: false,
        outOfStockItems: {}
    });
    const [exemptFromVATLineItemIds, setExemptFromVATLineItemIds] = useState<Set<string>>(new Set());

    useEffect(() => {
        setTaxCalculated(false);
    }, [exemptFromVATLineItemIds]);

    const workerRef = useRef<Worker | null>(null);

    const [data, setData] = useState<Cart>({} as Cart);

    const [isCartLoading, setIsCartLoading] = useState(true);

    const customerInfo = useMemo(() => {
        return (
            salesLinkInfo.customer && {
                id: salesLinkInfo.customer?.id,
                firstName: salesLinkInfo.customer.firstName,
                lastName: salesLinkInfo.customer.lastName,
                email: salesLinkInfo.customer.email,
                isB2B: salesLinkInfo.customer.isB2B,
                billingAddress: salesLinkInfo.customer.billingAddress,
                shippingAddress: salesLinkInfo.customer.shippingAddress,
                vatId: salesLinkInfo.customer.vatId,
                isCharity: salesLinkInfo.customer.isCharity
            }
        );
    }, [salesLinkInfo.customer]);

    // SalesLink Delegates code
    const [isDelegateModalOpen, setIsDelegateModalOpen] = useState(false);
    const { isDelegate } = useSalesLinkProduct();

    const cartHasProductsForDelegates = useMemo(() => {
        return data.lineItems?.some(lineItem => isDelegate(lineItem.productTypeKey!, !!customerInfo?.isB2B)) ?? false;
    }, [data.lineItems, customerInfo?.isB2B]);

    const openModalWithError = useCallback((modalProps: Omit<MessageModalProps['data'], 'type'>) => {
        const { message, heading, showCloseButton, closeButtonText, showCTAButton, ctaButtonText, ctaButtonAction } = modalProps;

        setMessageModalData({
            heading: heading ?? 'ERROR',
            message: message,
            type: 'error',
            showCloseButton: showCloseButton ?? true,
            closeButtonText: closeButtonText ?? 'I understand',
            showCTAButton: showCTAButton,
            ctaButtonText: ctaButtonText,
            ctaButtonAction: ctaButtonAction
        });
        setIsMessageModalOpen(true);
    }, []);

    const openModalWithInfo = useCallback((modalProps: Omit<MessageModalProps['data'], 'type'>) => {
        const { message, heading, showCloseButton, closeButtonText, showCTAButton, ctaButtonText, ctaButtonAction } = modalProps;

        setMessageModalData({
            heading: heading ?? 'NOTE',
            message: message,
            type: 'info',
            showCloseButton: showCloseButton ?? true,
            closeButtonText: closeButtonText ?? 'I understand',
            showCTAButton: showCTAButton,
            ctaButtonText: ctaButtonText,
            ctaButtonAction: ctaButtonAction
        });
        setIsMessageModalOpen(true);
    }, []);

    const setCartLineItemsDelegates = useCallback(async (productsDelegates: ProductsDelegates) => {
        const payload = {
            hash,
            unlockId: salesLinkInfo.unlockId,
            productsDelegates
        };

        setIsCartLoading(true);

        const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
            actionName: 'salesLink/setCartLineItemsDelegates',
            payload
        });

        if (result?.isError) {
            openModalWithError({
                message:
                    'Error during saving delegates to cart line items. Please try again. If the problem persists, contact support with the following error: ' +
                    JSON.stringify(result.error)
            });
            setIsCartLoading(false);
            return false;
        }

        if (result.data.isError) {
            openModalWithError({ message: result.data.errors[0].message });
            setIsCartLoading(false);
            return false;
        }

        setData(result.data.data.updatedCart);
        setIsCartLoading(false);
        return true;
    }, []);
    // Delegates code end

    const initialBillingFields: CustomerAddressFields = Object.keys(fields).reduce((acc, key) => {
        const fieldKey = key;

        if (fieldKey === 'email') {
            acc[fieldKey] = {
                ...fields[fieldKey],
                value: customerInfo?.email
            } as (typeof fields)[typeof fieldKey];
            return acc;
        }

        acc[fieldKey] = {
            ...fields[fieldKey],
            value: customerInfo?.billingAddress?.[fieldKey as keyof AddressExtended]
        } as (typeof fields)[typeof fieldKey];
        return acc;
    }, {} as CustomerAddressFields);

    const initialShippingFields: CustomerAddressFields = Object.keys(fields).reduce((acc, key) => {
        const fieldKey = key;

        if (fieldKey === 'email') {
            acc[fieldKey] = {
                ...fields[fieldKey],
                value: customerInfo?.email
            } as (typeof fields)[typeof fieldKey];
            return acc;
        }

        acc[fieldKey] = {
            ...fields[fieldKey],
            value: customerInfo?.shippingAddress?.[fieldKey as keyof AddressExtended]
        } as (typeof fields)[typeof fieldKey];
        return acc;
    }, {} as CustomerAddressFields);

    const [billingFields, setBillingFields] = useState(initialBillingFields);
    const [shippingFields, setShippingFields] = useState(initialShippingFields);
    const [sameAsBillingAddress, setSameAsBillingAddress] = useState(true);

    const billingAddressErrors = useMemo(() => {
        return Object.values(billingFields).reduce(
            (acc, field) => {
                const error = validate(field, fields);
                if (Object.keys(error).length) {
                    acc[field.name] = error;
                }
                return acc;
            },
            {} as Record<string, { showError?: boolean; message?: string }>
        );
    }, [billingFields]);

    const shippingAddressErrors = useMemo(() => {
        return Object.values(shippingFields).reduce(
            (acc, field) => {
                const error = validate(field, fields);
                if (Object.keys(error).length) {
                    acc[field.name] = error;
                }
                return acc;
            },
            {} as Record<string, { showError?: boolean; message?: string }>
        );
    }, [shippingFields]);

    const changeCartCustomerAddressValue = useCallback(
        async (addressFields: CustomerAddressFields, addressType: AddressType) => {
            const address = Object.keys(addressFields).reduce((acc, key) => {
                const field = addressFields[key];

                acc[field.name] = field.value?.toString();
                return acc;
            }, {} as CartCustomerAddress);

            if (hasErrorsInAddress(address)) {
                return false;
            }

            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                address,
                type: addressType
            };

            if (addressType === AddressType.BILLIING) {
                setTaxCalculated(false);
            }

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/setCartAddresses',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during saving the address. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error.message)
                });
                setIsCartLoading(false);
                return false;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);
                return false;
            }
            setTaxCalculated(false);
            setData(result.data.data.updatedCart);
            setIsCartLoading(false);
            return true;
        },
        [data]
    );

    const handleAddressFieldChange = useCallback(
        (field: Field, value: string | boolean, addressType: AddressType) => {
            if (addressType === AddressType.BILLIING) {
                if (field.name === 'country') {
                    billingFields.region.value = '';
                }
                setBillingFields({
                    ...billingFields,
                    [field.name]: {
                        ...field,
                        value
                    }
                } as CustomerAddressFields);
            } else {
                if (field.name === 'country') {
                    billingFields.region.value = '';
                }
                setShippingFields({
                    ...shippingFields,
                    [field.name]: {
                        ...field,
                        value
                    }
                } as CustomerAddressFields);
            }
        },
        [billingFields, shippingFields]
    );

    const resetAddressFields = useCallback(
        (addressType: AddressType) => {
            if (addressType === AddressType.BILLIING) {
                setBillingFields(initialBillingFields);
            } else {
                setShippingFields(initialShippingFields);
            }
        },
        [initialBillingFields, initialShippingFields]
    );

    const handleSameAsBillingAddressChange = useCallback(
        async (sameAsBilling: boolean) => {
            setSameAsBillingAddress(sameAsBilling);

            if (sameAsBilling) {
                setShippingFields(billingFields);
                if (!Object.keys(billingAddressErrors).length) {
                    return await changeCartCustomerAddressValue(billingFields, AddressType.SHIPPING);
                }
            } else {
                resetAddressFields(AddressType.SHIPPING);
                const initialShippingAddressHasError = Object.values(initialShippingFields).some(field => {
                    const error = validate(field, fields);
                    return !!Object.keys(error).length;
                });
                if (!initialShippingAddressHasError) {
                    return await changeCartCustomerAddressValue(initialShippingFields, AddressType.SHIPPING);
                }
            }
            return true;
        },
        [billingFields, initialShippingFields, changeCartCustomerAddressValue]
    );

    const handleCurrecySelection = useCallback(
        async (currency: string, la1Id: string) => {
            if (!selectedCurrency && currency) {
                setQuery({ ...query, currency, la1Id });
                const payload = {
                    hash,
                    unlockId: salesLinkInfo.unlockId,
                    currencyCode: currency
                };

                const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                    actionName: 'salesLink/createCart',
                    payload
                });

                if (result?.isError) {
                    openModalWithError({
                        message:
                            'Error during creating cart. Please refresh and try again. If the problem persists, contact support with the following error: ' +
                            JSON.stringify(result.error)
                    });
                    setIsCartLoading(false);

                    return;
                }
                if (result.data.isError) {
                    openModalWithError({ message: result.data.errors[0].message });
                    setIsCartLoading(false);
                    return;
                }

                setData(result.data.data.cart);

                const hasErrorsInBillingAddress = Object.keys(billingAddressErrors).length;
                const hasErrorsInShippingAddress = Object.keys(shippingAddressErrors).length;

                if (!hasErrorsInBillingAddress) {
                    await changeCartCustomerAddressValue(billingFields, AddressType.BILLIING);
                }

                if (!hasErrorsInShippingAddress) {
                    await changeCartCustomerAddressValue(shippingFields, AddressType.SHIPPING);
                    setSameAsBillingAddress(false);
                }

                if (!hasErrorsInBillingAddress && hasErrorsInShippingAddress) {
                    await changeCartCustomerAddressValue(billingFields, AddressType.SHIPPING);
                    setSameAsBillingAddress(true);
                }

                setSelectedCurrency(currency);
                setIsCartLoading(false);
            }
        },
        [
            selectedCurrency,
            query,
            hash,
            salesLinkInfo.unlockId,
            setQuery,
            billingAddressErrors,
            shippingAddressErrors,
            billingFields,
            shippingFields,
            changeCartCustomerAddressValue
        ]
    );

    const cartHasValidBillingAddress = useMemo(() => {
        return !hasErrorsInAddress(data?.billingAddress ?? {});
    }, [data.billingAddress]);

    const cartHasValidShippingAddress = useMemo(() => {
        return !hasErrorsInAddress(data?.shippingAddress ?? {});
    }, [data.shippingAddress]);

    const checkIfShippingRequired = (lineItems: LineItem[]) => {
        return !!lineItems?.some(lineItem => lineItem.variant?.attributes?.isShippingRequired);
    };

    const isShippingRequired = useMemo(() => {
        return checkIfShippingRequired(data.lineItems ?? []);
    }, [data?.lineItems]);

    const transaction = useMemo(() => {
        const cartData = data;
        const currency = selectedCurrency || 'GBP';

        if (!cartData?.lineItems?.length)
            return {
                subtotal: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                discount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                tax: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                shipping: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                total: { centAmount: 0, currencyCode: currency, fractionDigits: 2 },
                totalAmount: { centAmount: 0, currencyCode: currency, fractionDigits: 2 }
            };

        const currencyCode = cartData.sum?.currencyCode ?? currency;
        const fractionDigits = cartData.sum?.fractionDigits ?? 2;

        const totalAmount = cartData.sum!.centAmount!;
        const subTotalAmount = cartData.lineItems.reduce((acc, curr) => acc + (curr.price?.centAmount ?? 0) * curr.count!, 0);

        const discountedAmount =
            cartData.lineItems.reduce(
                (acc, curr) => acc + ((curr.price?.centAmount ?? 0) * curr.count! - (curr.totalPrice?.centAmount ?? 0)),
                0
            ) + (cartData?.discountedAmount?.centAmount ?? 0);

        const totalTax = totalAmount > 0 ? cartData.taxed?.amount.centAmount ?? 0 : 0;

        const totalShipping =
            totalAmount > 0
                ? cartData.shippingInfo?.price?.centAmount ?? cartData.availableShippingMethods?.[0]?.rates?.[0]?.price?.centAmount ?? 0
                : 0;

        return {
            subtotal: {
                centAmount: subTotalAmount,
                currencyCode,
                fractionDigits
            },
            discount: {
                centAmount: discountedAmount,
                currencyCode,
                fractionDigits
            },
            shipping: {
                centAmount: totalShipping,
                currencyCode,
                fractionDigits
            },
            tax: {
                centAmount: totalTax,
                currencyCode,
                fractionDigits
            },
            total: {
                centAmount: totalAmount + totalTax,
                currencyCode,
                fractionDigits
            },
            totalAmount: {
                centAmount: totalAmount,
                currencyCode,
                fractionDigits
            }
        };
    }, [data, selectedCurrency]);

    const isAddedToCart = useCallback((sku: string) => data?.lineItems?.some(lineItem => lineItem.variant?.sku === sku) ?? false, [data]);

    const validateEmail = useCallback(
        (email: string) => {
            if (email.length && email !== customerInfo?.email) {
                return 'The contact email address is not correct';
            } else {
                return '';
            }
        },
        [customerInfo]
    );

    const checkIfCreateInHouseEventIsAvailable = useCallback(async () => {
        const payload = {
            hash
        };

        const result = await sdk.callAction<SalesLinkResponse<CheckIfCreateInHouseIsAvailableSuccessResponse>>({
            actionName: 'salesLink/checkIfCreateInHouseEventIsAvailable',
            payload
        });

        if (result?.isError) {
            openModalWithError({
                message:
                    'In-house creation is not available. Reason: Unknown error occurred. Please try again later. If the problem persists, contact support.'
            });
            return false;
        }

        if (result.data.isError) {
            openModalWithError({ message: 'In-house creation is not available. Reason: ' + result.data.errors[0].message });
            return false;
        }

        const isAvailable = result.data.data.available;

        if (!isAvailable) {
            const reasonCode = result.data.data.reasonCode!;
            const reasonCodeErrorMessageEnum = {
                PIM_DOWN: 'PIM is down',
                CIM_CONTACT_NO_ACCOUNT_FOUND: 'No account found for contact in CIM',
                CIM_NO_ADMINISTRATE_ACCOUNT: 'No administrate account found'
            };
            openModalWithError({ message: 'In-house creation is not available. Reason: ' + reasonCodeErrorMessageEnum[reasonCode] });
            return false;
        }

        return true;
    }, [hash]);

    const executeSearch = useCallback(
        (sku: string) => {
            searchProductsBySku(sku);
            setIsMessageModalOpen(false);
            const filters = document.body.querySelector('#product-filters');
            filters?.scrollIntoView({ behavior: 'smooth' });
        },
        [setQuery, searchProductsBySku, productTypes]
    );

    const startWorkerToCheckForSku = useCallback(
        (sku: string) => {
            // Only run this on the client-side
            if (typeof window !== 'undefined' && sku) {
                // Initialize the Web Worker
                const worker = new Worker(new URL('workers/tryToFindProductBySkuAtIntervalWorker', import.meta.url));
                workerRef.current = worker;

                const config = sdk.getConfig('en');

                // Send data to the worker to start checking if product is in CT every 40 seconds for 30 minutes
                worker.postMessage({ sku, config, interval: 40000, maxAttempts: 45, maxAttemptFailuresInARow: 3 });
                // Handle messages from the worker
                worker.onmessage = event => {
                    const { success } = event.data;

                    if (success) {
                        openModalWithInfo({
                            heading: 'PRODUCT READY',
                            message: `In-house product with SKU ${sku} was imported in Commercetools and is ready to be purchased.`,
                            closeButtonText: 'Close',
                            showCTAButton: true,
                            ctaButtonText: 'View Product in Search results',
                            ctaButtonAction: () => executeSearch(sku)
                        });

                        worker.terminate(); // Terminate the worker when SKU is found
                    } else {
                        console.error('An error occurred in the worker:', event.data);
                        openModalWithError({
                            message: `We were not able to find the status of the created in-house product ${sku}. Please contact support and provide this sku: ${sku}.`
                        });
                        worker.terminate(); // Terminate the worker if an error occurs
                    }
                };

                // Handle worker errors
                worker.onerror = error => {
                    console.error('An error occurred in the worker:', error);
                    openModalWithError({
                        message: `We were not able to find the status of the created in-house product ${sku}. Please contact support and provide this sku: ${sku}.`
                    });
                    worker.terminate(); // Terminate the worker in case of an error
                };
            }
        },
        [executeSearch, openModalWithError, openModalWithInfo]
    );

    const createInHouseEvent = useCallback(
        async (productInfo: CreateInHousePayload['product']) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                productInfo
            };

            const result = await sdk.callAction<SalesLinkResponse<CreateInHouseSuccessResponse>>({
                actionName: 'salesLink/createInHouseEvent',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message: 'Error during creating in-house event. Please try again. If the problem persists, contact support'
                });
                return false;
            }

            if (result.data.isError) {
                openModalWithError({
                    message:
                        ' Error during creating in-house event. Please try again. If the problem persists, contact support with the following error: ' +
                        result.data.errors[0].message
                });
                return false;
            }

            const newPoductSku = result.data.data.product.sku;

            if (!newPoductSku) {
                return false;
            }

            const createInHouseSuccessMessage = salesLinkSettings.createInHouseSuccessMessage.replace('${sku}', newPoductSku);

            openModalWithInfo({ message: createInHouseSuccessMessage });

            startWorkerToCheckForSku(newPoductSku);

            return newPoductSku;
        },
        [
            hash,
            salesLinkInfo.unlockId,
            salesLinkSettings.createInHouseSuccessMessage,
            startWorkerToCheckForSku,
            openModalWithError,
            openModalWithInfo
        ]
    );

    const addToCart = useCallback(
        async (product: Product, selectedVariant: Variant, attributes?: LineItemAddToCartAttributes) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                productTypeKey: product.productTypeKey,
                bundle: {
                    selectedBundleComponentVariantSKUs: attributes?.selectedBundleComponentVariantSKUs,
                    selectedCommencementDate: attributes?.selectedCommencementDate
                },
                externalPrice: selectedVariant.prices?.[selectedCurrency],
                sku: selectedVariant.sku,
                quantity: 1
            };

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/addToCart',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during adding to cart. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error.message)
                });
                setIsCartLoading(false);

                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);

                return;
            }
            setTaxCalculated(false);
            const updatedCart = result.data.data.updatedCart;
            const updatedCartRequreShipping = checkIfShippingRequired(updatedCart.lineItems ?? []);

            if (updatedCartRequreShipping && !isShippingRequired) {
                openModalWithError({ message: 'Some products require shipping. Please, check the shipping address' });
            }

            setData(updatedCart);
            setIsCartLoading(false);
        },
        [hash, salesLinkInfo.unlockId, isShippingRequired, selectedCurrency]
    );

    const removeFromCart = useCallback(
        async (lineItemId: string) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                lineItemId
            };

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/removeLineItem',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during removing from cart. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error)
                });
                setIsCartLoading(false);

                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);

                return;
            }

            setTaxCalculated(false);
            setData(result.data.data.updatedCart);
            setIsCartLoading(false);
        },
        [hash, salesLinkInfo.unlockId]
    );

    const changeLineItemQuantity = useCallback(
        async (lineItemId: string, newQuantity: number, externalPrice: Money, bundleComponents?: LineItem[]) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                lineItemId,
                quantity: newQuantity,
                externalPrice,
                bundleComponents
            };

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/changeLineItemQuantity',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during changing quantity. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error)
                });
                setIsCartLoading(false);
                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);
                return;
            }

            setInventoryCheck(result.data.data.inventoryCheck ?? { hasOutOfStockItems: false, outOfStockItems: {} });
            setTaxCalculated(false);

            setData(result.data.data.updatedCart);
            setIsCartLoading(false);
        },
        []
    );

    const changeLineItemPrice = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (lineItemId: string, externalPrice: Money) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                lineItemId,
                externalPrice
            };

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/changeLineItemPrice',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during total price change. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error)
                });
                setIsCartLoading(false);
                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);
                return;
            }

            setTaxCalculated(false);
            setData(result.data.data.updatedCart);
            setIsCartLoading(false);
        },
        [data]
    );

    const changeLineItemCustomField = useCallback(
        async (lineItemId: string, customField: { name: string; value: any }, bundleComponents?: LineItem[]) => {
            const payload = {
                hash,
                unlockId: salesLinkInfo.unlockId,
                lineItemId,
                customField,
                bundleComponents
            };

            setIsCartLoading(true);

            const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
                actionName: 'salesLink/changeLineItemCustomField',
                payload
            });

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during changing custom field. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error)
                });
                setIsCartLoading(false);
                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);
                return;
            }

            setData(result.data.data.updatedCart);
            setIsCartLoading(false);
        },
        [data]
    );

    const purchaseCart = useCallback(
        async (paymentMethod: string, emailConfirmed?: boolean, isDelegatesSet?: boolean) => {
            if (!termsAccepted) {
                openModalWithError({ message: 'Please accept the terms and conditions' });
                return;
            }

            setPaymentMethod(paymentMethod);

            // SalesLink Delegates code

            if (
                salesLinkSettings.delegatesAssignmentBeforeOrderEnabled &&
                cartHasProductsForDelegates &&
                customerInfo?.isB2B &&
                !isDelegatesSet
            ) {
                setIsDelegateModalOpen(true);
                return;
            }

            // SalesLink Delegates code end

            if (!emailConfirmed) {
                setIsEmailModalOpen(true);
                return;
            }

            // purchase cart

            setIsCartLoading(true);

            const payload = {
                hash,
                paymentMethod: paymentMethod,
                unlockId: salesLinkInfo.unlockId,
                isPONumberRequired: isPONumberRequired
            };

            console.info('---> purchaseCart checkout payload', payload);

            const result = await sdk.callAction<SalesLinkResponse<CheckoutResponse>>({
                actionName: 'salesLink/checkout',
                payload
            });
            console.log('Purchase result:', result);

            if (result?.isError) {
                openModalWithError({
                    message:
                        'Error during purchase. Please try again. If the problem persists, contact support with the following error: ' +
                        JSON.stringify(result.error.message)
                });
                setIsCartLoading(false);

                return;
            }

            if (result.data.isError) {
                openModalWithError({ message: result.data.errors[0].message });
                setIsCartLoading(false);

                if (result.data.errors[0].code === 'cart.outOfStock') {
                    setInventoryCheck(result.data.errors[0].meta);
                }

                return;
            }

            const order = result.data.data.order;
            console.log('Order created: ', order);

            const siteUrl = window.location.origin;

            if (paymentMethod === PaymentMethod.CREDIT_CARD) {
                // show payment link
                const paymentHash = result.data.data.hash;
                setPaymentLink(`${siteUrl}/sales-link/payment/${paymentHash}`);

                setIsPaymentLinkModalOpen(true);
            }

            const absoluteLearnerAssignmentLink = order.learnerAssignmentLink;
            if (absoluteLearnerAssignmentLink) {
                const learnerAssignmentLink = `${siteUrl}${absoluteLearnerAssignmentLink}`;
                setLearnerAssignmentPageLink(learnerAssignmentLink);
            }

            if (paymentMethod === PaymentMethod.INVOICE) {
                // show thank you page
                if (typeof window !== 'undefined') {
                    window.sessionStorage.setItem('salesLinkLastPlacedOrder', JSON.stringify(order));
                }
                router.push('/sales-link/thank-you');
            }
        },
        [
            data,
            customerInfo,
            termsAccepted,
            hash,
            salesLinkInfo.unlockId,
            cartHasProductsForDelegates,
            salesLinkSettings.delegatesAssignmentBeforeOrderEnabled,
            isPONumberRequired
        ]
    );

    const calculateTaxes = useCallback(async () => {
        setIsCartLoading(true);

        const payload = {
            hash,
            cart: data,
            exemptFromVATLineItemIds: Array.from(exemptFromVATLineItemIds),
            account: salesLinkInfo.customer,
            unlockId: salesLinkInfo.unlockId
        };

        const result = await sdk.callAction<SalesLinkResponse<UpdateCartSuccessResponse>>({
            actionName: 'salesLink/calculateTaxes',
            payload
        });

        console.log('---> setTaxes result', result);

        if (result?.isError) {
            openModalWithError({
                message:
                    'Error during calculating taxes. Please try again. If the problem persists, contact support with the following error: ' +
                    JSON.stringify(result.error)
            });
            setIsCartLoading(false);

            return;
        }

        if (result.data.isError) {
            openModalWithError({ message: result.data.errors[0].message });
            setIsCartLoading(false);

            if (result.data.errors[0].code === 'cart.billingAddress.error') {
                scrollToError('billing-address-wrapper');
            }

            if (result.data.errors[0].code === 'cart.outOfStock') {
                setInventoryCheck(result.data.errors[0].meta);
            }

            return;
        }

        setInventoryCheck(result.data.data.inventoryCheck ?? { hasOutOfStockItems: false, outOfStockItems: {} });

        setData(result.data.data.updatedCart);
        setTaxCalculated(true);
        setIsCartLoading(false);
    }, [data, exemptFromVATLineItemIds]);

    const value = useMemo(
        () => ({
            data,
            inventoryCheck,
            billingFields,
            shippingFields,
            handleAddressFieldChange,
            sameAsBillingAddress,
            handleSameAsBillingAddressChange,
            resetAddressFields,
            cartHasValidBillingAddress,
            cartHasValidShippingAddress,
            isShippingRequired,
            transaction,
            customerInfo,
            isCartLoading,
            taxCalculated,
            isAddedToCart,
            checkIfCreateInHouseEventIsAvailable,
            createInHouseEvent,
            addToCart,
            removeFromCart,
            changeLineItemQuantity,
            changeLineItemPrice,
            changeLineItemCustomField,
            validateEmail,
            changeCartCustomerAddressValue,
            purchaseCart,
            calculateTaxes,
            selectedCurrency,
            handleCurrecySelection,
            termsAccepted,
            setTermsAccepted,
            setIsPONumberRequired,
            isPONumberRequired,
            exemptFromVATLineItemIds,
            setExemptFromVATLineItemIds
        }),
        [
            data,
            inventoryCheck,
            billingFields,
            shippingFields,
            handleAddressFieldChange,
            sameAsBillingAddress,
            handleSameAsBillingAddressChange,
            resetAddressFields,
            cartHasValidBillingAddress,
            cartHasValidShippingAddress,
            isShippingRequired,
            transaction,
            customerInfo,
            isCartLoading,
            taxCalculated,
            isAddedToCart,
            checkIfCreateInHouseEventIsAvailable,
            createInHouseEvent,
            addToCart,
            removeFromCart,
            changeLineItemQuantity,
            changeLineItemPrice,
            changeLineItemCustomField,
            validateEmail,
            changeCartCustomerAddressValue,
            purchaseCart,
            calculateTaxes,
            selectedCurrency,
            handleCurrecySelection,
            termsAccepted,
            setTermsAccepted,
            setIsPONumberRequired,
            isPONumberRequired,
            exemptFromVATLineItemIds,
            setExemptFromVATLineItemIds
        ]
    );

    return (
        <SalesLinkCartContext.Provider value={value}>
            {children}
            <MessageModal data={messageModalData} isOpen={isMessageModalOpen} closeModal={() => setIsMessageModalOpen(false)} />
            {/* SalesLink Delegates code */}
            <DelegatesModal
                customer={customerInfo!}
                isOpen={isDelegateModalOpen}
                onClose={() => setIsDelegateModalOpen(false)}
                onSubmit={async (productsDelegates: ProductsDelegates) => {
                    const delegatesSet = await setCartLineItemsDelegates(productsDelegates);

                    if (delegatesSet) {
                        void purchaseCart(paymentMethod, false, true);
                    }
                    setIsDelegateModalOpen(false);
                }}
            />
            {/* SalesLink Delegates code end */}
            <EmailConfirmationModal
                isOpen={isEmailModalOpen}
                onClose={() => setIsEmailModalOpen(false)}
                onSubmit={({ email }) => {
                    const validateEmailError = validateEmail(email);

                    if (!validateEmailError) {
                        void purchaseCart(paymentMethod, true, true);
                        setIsEmailModalOpen(false);
                    }
                    return validateEmailError;
                }}
            />
            <PaymentLinkModal
                isOpen={isPaymentLinkModalOpen}
                paymentLink={paymentLink}
                learnerAssignmentLink={learnerAssignmentPageLink}
                closeModal={() => {
                    setIsPaymentLinkModalOpen(false);
                    router.refresh();
                }}
            />
        </SalesLinkCartContext.Provider>
    );
};

export default SalesLinkCartProvider;

export const useSalesLinkCartContext = () => useContext(SalesLinkCartContext);
