'use client';
import React, { useMemo } from 'react';
import type { ChangeEvent } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import Input from 'components/commercetools-ui/atoms/input';
import { useSalesLinkDataContext } from 'providers/sales-link/data';

interface DropdownFilter {
    label: string;
    name: string;
    type: 'dropdown';
    value?: string;
    items: { label: string; value: string }[];
    loading: boolean;
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
    required?: boolean;
    className?: string;
}

interface InputFilter {
    label: string;
    name: string;
    type: 'input';
    value?: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    required?: boolean;
    className?: string;
}

type Filter = DropdownFilter | InputFilter;

const ProductFilters: React.FC = () => {
    const { productTypes, productTypesLoading, query, setQuery, searchProducts } = useSalesLinkDataContext();

    const productTypesItems = useMemo(() => {
        const mappedProductTypes = productTypes?.map(type => ({ label: type.name, value: type.id }));
        return [{ label: 'Select product type', value: '' }, ...mappedProductTypes];
    }, [productTypes]);

    const filters: Filter[] = useMemo(() => {
        return [
            {
                label: 'Product Type',
                name: 'productType',
                type: 'dropdown',
                value: query.productType,
                items: productTypesItems,
                loading: productTypesLoading,
                onChange: (e: ChangeEvent<HTMLSelectElement>) => setQuery({ ...query, productType: e.target.value }),
                required: true
            },
            {
                label: 'Search term',
                name: 'term',
                type: 'input',
                value: query.term,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => setQuery({ ...query, term: e.target.value.slice(0, 1000) })
            },
            {
                label: 'Search for:',
                name: 'searchType',
                type: 'dropdown',
                value: query.searchType,
                items: [
                    { label: 'Product name', value: 'name' },
                    { label: 'SKU', value: 'sku' },
                    { label: 'Master product sku', value: 'masterSku' }
                ],
                loading: productTypesLoading,
                onChange: (e: ChangeEvent<HTMLSelectElement>) =>
                    setQuery({ ...query, searchType: e.target.value as 'name' | 'sku' | 'masterSku' }),
                required: true
            }
        ];
    }, [query, productTypesLoading, productTypesItems, setQuery]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        searchProducts();
    };

    return (
        <>
            <h2 id="product-filters" className="text-20">
                Product filters
            </h2>
            <div className="mt-10 grid grid-cols-2 gap-12 md:grid-cols-5">
                <div>
                    <p className="mb-8 text-md font-bold">Currency</p>
                    <p className="border-checkout-border rounded-md border bg-neutral-200 p-8 font-bold">{query.currency}</p>
                </div>
                <div>
                    <p className="mb-8 text-md font-bold">LA1 Code</p>
                    <p className="border-checkout-border rounded-md border bg-neutral-200 p-8 font-bold">{query.la1Id}</p>
                </div>
            </div>
            <form className="mt-10 grid grid-cols-2 gap-12 md:grid-cols-[1fr_2fr_1fr_1fr]" onSubmit={handleSubmit}>
                {filters.map(filter => {
                    if (filter.type === 'dropdown') {
                        return (
                            <div key={filter.name} className={filter.className}>
                                <Dropdown
                                    items={filter.items}
                                    label={filter.label}
                                    onChange={filter.onChange}
                                    value={filter.value}
                                    required={filter.required}
                                    name={filter.name}
                                    id={filter.name}
                                />
                            </div>
                        );
                    } else if (filter.type === 'input') {
                        return (
                            <div key={filter.name} className={filter.className}>
                                <Input
                                    value={filter.value}
                                    onChange={filter.onChange}
                                    label={filter.label}
                                    placeholder={filter.label}
                                    required={filter.required}
                                    name={filter.name}
                                    id={filter.name}
                                />
                            </div>
                        );
                    }
                })}
                <div className="col-span-2 md:col-span-1">
                    <Button className="mt-16 w-full rounded-sm text-14 font-medium md:mt-32" variant="primary" type="submit">
                        Search
                    </Button>
                </div>
            </form>
        </>
    );
};

export default ProductFilters;
